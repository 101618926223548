<template>
  <donation-table filter="program" />
</template>
<script>
import DonationTable from "@/app/donations-website/components/DonationsTable";

export default {
  name: "DonationMain",
  components: {
    DonationTable
  }
};
</script>
